import Head from "next/head";
import logo from "../public/images/logo/logo.svg";

const PageHead = ({ title }) => {
    const defaultTitle = "BreatheInWisdom - Discover the path to purpose, fulfillment, and inner harmony";
  const defaultDescription =
    "Unlock the power of mindfulness and self-help with BreatheInWisdom. Explore expert-guided meditation, manifestation techniques, stress relief meditations, and daily mindfulness practices. Achieve emotional healing, productivity, and inner peace.";
  const defaultKeywords =
    "Meditation for beginners, Mindfulness courses online, Manifestation techniques, Stress relief meditation, Guided meditation sessions, Online self-help courses, Benefits of meditation, Daily mindfulness practices, Visualization for success, Emotional healing meditation, Focus and productivity meditation, Self-help";
  const defaultOgImage = logo.src;
  const jsonLdPrograms = {
    "@context": "https://schema.org",
    "@type": "Programs",
    "name": "Online Mindfulness, Meditation, Manifestation, Self Help Programs",
    "description":
      "Unlock the power of mindfulness with expert-guided meditation programs, manifestation techniques, self-help programs, and stress relief meditations.",
    "provider": {
      "@type": "Organization",
      "name": "BreatheInWisdom",
      "url": "https://breatheinwisdom.com",
    },
  };
  const jsonLdWebSite = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "BreatheInWisdom",
    "url": "https://breatheinwisdom.com/",
    "potentialAction": [
      {
        "@type": "SearchAction",
        "target": "https://breatheinwisdom.com/search?q={search_term_string}",
        "query-input": "required name=search_term_string",
      },
      {
        "@type": "EntryPoint",
        "urlTemplate": "https://breatheinwisdom.com/login",
        "actionPlatform": [
          "http://schema.org/DesktopWebPlatform",
          "http://schema.org/AndroidPlatform",
          "http://schema.org/iOSPlatform",
        ],
      },
      {
        "@type": "EntryPoint",
        "urlTemplate": "https://breatheinwisdom.com/all-programs",
        "actionPlatform": [
          "http://schema.org/DesktopWebPlatform",
          "http://schema.org/AndroidPlatform",
          "http://schema.org/iOSPlatform",
        ],
      },
      {
        "@type": "EntryPoint",
        "urlTemplate": "https://breatheinwisdom.com/offers",
        "actionPlatform": [
          "http://schema.org/DesktopWebPlatform",
          "http://schema.org/AndroidPlatform",
          "http://schema.org/iOSPlatform",
        ],
      },
    ],
  };

  return (
    <>
        <Head>
            {/*<title>BreatheInWisdom | Transform Your Life with Expert Mindfulness & Manifestation Programs</title>*/}
            {/*<meta name="description" content="Page Description" />*/}
            {/*<meta name="viewport" content="width=device-width, initial-scale=1" />*/}
            {/*<link rel="icon" href="/favicon.svg" />*/}
            <title>{defaultTitle}</title>
            <meta name="description" content={defaultDescription}/>
            <meta name="keywords" content={defaultKeywords}/>
            <meta name="author" content="BreatheInWisdom"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <link rel="icon" href="/favicon.svg"/>
            {/* Open Graph Meta Tags */}
            <meta property="og:title" content={title || defaultTitle}/>
            <meta property="og:description" content={defaultDescription}/>
            <meta property="og:image" content={defaultOgImage}/>
            <meta property="og:url" content="https://breatheinwisdom.com"/>
            <meta property="og:type" content="website"/>
            {/* JSON-LD Structured Data */}
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(jsonLdPrograms)}}
            />
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(jsonLdWebSite)}}
            />
        </Head>
    </>
  );
};

export default PageHead;
