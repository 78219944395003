import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";

import { useAppContext } from "@/context/Context";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import Pagination from "@/components/Common/Pagination";
import ApiService from "@/utils/ApiServices";
import ApiUrl from "@/utils/ApiUrl";
import Cookies from "js-cookie";

const CourseFilterOneToggle = ({ course, start, end }) => {
  const { toggle } = useAppContext();
  const [courses, setCourse] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [statusAPIcall, setStatusAPIcall] = useState(false);
  const router = useRouter()
  let parsedUserInfo = null;
  const userInfo = Cookies.get('userInfo') || '{}';;
  parsedUserInfo = JSON.parse(userInfo);
  const checkPrerequisitePurchaseStatus = async (course) => {
    var purchasedPrerequisite = false;
    if (course?.isPrerequisite) {
      try {
        const userId = parsedUserInfo?.user?.id;
        const courseId = course?.prerequisiteCourse?.id;
        const urlWithParams = `${ApiUrl.GET_USER_PREREQUISITE_STATUS}?courseId=${courseId}&userId=${userId}`;
        const response = await ApiService.getApiService(urlWithParams,);
        if (response?.status === "success") {
            purchasedPrerequisite = response?.results?.status;
        }
      } catch (error) {
        console.error("Error checking prerequisite purchase status:", error);
      }
    }
    try {
      const encodedData = btoa(JSON.stringify({
        id: course?._id,
        packageName: course?.title,
        price: course?.discountPrice || course?.price,
        prerequisiteCourseId: course?.isPrerequisite && !purchasedPrerequisite
            ? course?.prerequisiteCourse?.id
            : null,
        prerequisiteCourseTitle: course?.isPrerequisite && !purchasedPrerequisite
            ? course?.prerequisiteCourse?.title
            : null,
        type: 'course'
      }));
      if (parsedUserInfo?.user) {
        router.push(`/checkout?data=${encodedData}`)
      } else {
        router.push(`/login?redirect=${router.asPath}`)
      }
    }
    catch (error) {
      console.error("Error checking prerequisite purchase status:", error);
    }
  };
  const handleBuyNow = (course) => {
    checkPrerequisitePurchaseStatus(course);
  };

  const startIndex = (page - 1) * 6;

  const getSelectedCourse = courses.slice(startIndex, startIndex + 6);

  const handleClick = (num) => {
    setPage(num);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setCourse(course);
    setTotalPages(Math.ceil(course.length / 6));
  }, [setTotalPages, setCourse, getSelectedCourse]);

  return (
    <>
      <div
        className={`rbt-course-grid-column ${!toggle ? "active-list-view" : ""
          }`}
      >
        {course.slice(start, end).map((data, index) => (
          <div className="course-grid-3" key={index}>
            <div
              className={`rbt-card variation-01 rbt-hover ${!toggle ? "card-list-2" : ""
                }`}
            >
              <div className="rbt-card-img">
                <Link href={`/course-details/${data?._id}`}>
                  <Image
                    style={{ height: "244px" }}
                    // src={"/images/course/course-list-01.jpg"}
                    src={data?.image ? data?.image : "/images/course/course-list-01.jpg"}

                    width={355}
                    height={244}
                    alt="Card image"
                  />
                  {data?.discountPercent>0?<div className="rbt-badge-3 bg-white">
                    <span>{data?.discountPercent}%</span>
                    <span>Off</span>
                  </div>:null}
                </Link>
              </div>
              <div className="rbt-card-body">


                <h4 className="rbt-card-title">
                  <Link href={`/course-details/${data?._id}`}>
                    {data?.title}
                  </Link>
                </h4>

                <ul className="rbt-meta">
                  <li>
                    <i className="feather-book"></i>
                    {data?.numberOfLessons} Lessons
                  </li>
                  {/* <li>
                    <i className="feather-users"></i>
                    {data?.courseContents?.length ? data?.courseContents?.length : 0} Students
                  </li> */}
                </ul>

                <p className="rbt-card-text">{data?.shortDescription}</p>
                <div className="rbt-author-meta mb--10">
                  {/* <div className="rbt-avater">
                    <Link href="#">
                      <Image
                        src={data.userImg}
                        width={33}
                        height={33}
                        alt="Sophia Jaymes"
                      />
                    </Link>
                  </div> */}
                  {/* <div className="rbt-author-info">
                    By <Link href={`/profile/${data.id}`}>{data.userName}</Link>{" "}
                    In <Link href="#">{data.userCategory}</Link>
                  </div> */}
                </div>
                <div className="d-flex flex-column h-100">
                  {
                    data?.isPayment?(
                      <Link href={`/course-details/${data?._id}`} className="add-to-card-button">
                      <button
                        style={{ height: "50px", lineHeight: "50px" }}
                        className="rbt-btn btn-gradient icon-hover w-100 d-block text-center"
                      >
                        <span className="btn-text">View</span>
                        <span className="btn-icon">
                          <i className="feather-arrow-right"></i>
                        </span>
                      </button>
                    </Link>

                    ):(
                      <div className="rbt-card-bottom mt-auto">
                      <div className="rbt-price">
                        <span className="current-price">${data.discountPrice}</span>
                        {data.discountPercent!==0 && (
                          <span className="off-price">${data.price}</span>
                        )}
                      </div>
  
                      <div className="add-to-card-button">
                        <button
                          style={{ height: "50px", lineHeight: "50px" }}
                          className="rbt-btn btn-gradient icon-hover w-100 d-block text-center"
                          onClick={() => handleBuyNow(data)}
                        >
                          <span className="btn-text">Enroll Now</span>
                          <span className="btn-icon">
                            <i className="feather-arrow-right"></i>
                          </span>
                        </button>
                      </div>
                    </div>

                    )
                  }


                      


                  
                </div>

              </div>
            </div>
          </div>
        ))}
      </div>

      {/* {course.length > 6 ? (
        <div className="row">
          <div className="col-lg-12 mt--60">
            <Pagination
              totalPages={totalPages}
              pageNumber={page}
              handleClick={handleClick}
            />
          </div>
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default CourseFilterOneToggle;
